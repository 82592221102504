import { AspectRatio, Box } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  ICON_TYPES_PREFIX_MAP,
  INTERNAL_ICONS_MAP,
} from "../../constants/icons";

/**
 * @param {object} params
 * @param {string} params.defaultSize
 * @param {string} params.defaultLightColor
 * @param {string} params.defaultDarkColor
 */
export function getIconComponent({
  defaultSize,
  defaultLightColor,
  defaultDarkColor,
}) {
  const IconComponent = memo(
    /**
     * @typedef {Object} Props
     * @property {string} icon
     * @property {string} [size]
     * @property {string} [color]
     */
    /**
     * @param {Props} props
     */
    function Icon({ icon, size = defaultSize, color: _color }) {
      const intl = useIntl();

      const defaultColor = useColorModeValue(
        defaultLightColor,
        defaultDarkColor,
      );

      const prefix = `${icon.split("_")[0]}_`;

      const iconType = useMemo(() => {
        return ICON_TYPES_PREFIX_MAP.get(prefix);
      }, [prefix]);

      const color = _color ?? defaultColor;

      const errorMessage = useMemo(() => {
        return intl.formatMessage({
          defaultMessage: "Icône introuvable",
        });
      }, [intl]);

      return (
        <AspectRatio ratio={1} w={size}>
          <>
            {(() => {
              switch (iconType?.type) {
                case "internal":
                  return (
                    <Box display="block" color={color} w={size}>
                      {INTERNAL_ICONS_MAP.get(icon)?.element ?? errorMessage}
                    </Box>
                  );
                case "materialSymbols":
                  return (
                    <Box
                      as="span"
                      className="material-symbols-outlined"
                      display="block"
                      fontSize={size}
                      color={color}>
                      {icon.replace(prefix, "")}
                    </Box>
                  );
                default:
                  return errorMessage;
              }
            })()}
          </>
        </AspectRatio>
      );
    },
  );
  return IconComponent;
}
