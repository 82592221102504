import { Box, Button, Stack, Text, Wrap } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { memo } from "react";
import { FormattedMessage } from "react-intl";

/**
 * @param {{
 * accept: () => void,
 * refuse: () => void,
 * close: () => void,
 * }} param0
 * @returns
 */
function CookiesModal({ accept, refuse, close }) {
  return (
    <motion.div
      style={{ position: "fixed", left: 0, bottom: 0 }}
      initial={{
        transform: "translateY(25%)",
        opacity: 0,
      }}
      animate={{
        transform: "translateY(0%)",
        opacity: 1,
      }}
      exit={{
        transform: "translateY(25%)",
        opacity: 0,
      }}>
      <Box
        role="dialog"
        aria-labelledby="modal-cookies-title"
        aria-describedby="modal-cookies-description"
        m="1rem"
        bg="#fff"
        p="1.5rem"
        maxW="520px"
        boxShadow="0px 2px 4px rgba(57, 58, 151, 0.25);">
        <Stack spacing="1.25rem">
          <Text
            id="modal-cookies-title"
            flexGrow={1}
            fontSize="2rem"
            lineHeight="2.4881rem"
            color="secondary">
            <FormattedMessage defaultMessage="Utilisation des cookies" />
          </Text>

          <Text
            id="modal-cookies-description"
            fontSize="1.125rem"
            lineHeight="1.3994rem">
            <FormattedMessage defaultMessage="Notre site utilise des cookies pour vous proposer du contenu personnalisé. Ces informations sont conservées 6 mois." />
          </Text>

          <Wrap spacing="1rem" justify="flex-end">
            <Button onClick={close} variant="ghost" colorScheme="brandPrimary">
              <FormattedMessage defaultMessage="Fermer" />
            </Button>

            <Button onClick={refuse} variant="ghost" colorScheme="brandPrimary">
              <FormattedMessage defaultMessage="Je refuse" />
            </Button>

            <Button onClick={accept} colorScheme="brandPrimary">
              <FormattedMessage defaultMessage="J'accepte" />
            </Button>
          </Wrap>
        </Stack>
      </Box>
    </motion.div>
  );
}

export default memo(CookiesModal);
