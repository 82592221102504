/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const FORM_STYLE = {
  parts: ["container"],
  baseStyle: {
    container: {
      label: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.3125rem",
        color: "var(--chakra-colors-brandPrimary-400)",
      },
    },
  },
};
