import { Box, Center, HStack, Image, Text } from "@chakra-ui/react";
import { useConfigurationMenu } from "@raiden/library/hooks/useConfigurationMenu";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { memo, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MainMenuItem } from "./MainMenuItem";

export const MenuDesktop = memo(function MenuDesktop() {
  const intl = useIntl();

  const { menu } = useConfigurationMenu({ alias: "header" });

  const [activePanelId, setActivePanelId] = useState(
    /** @type {number | null} */ (null),
  );

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setActivePanelId(null);
    };

    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("hashChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
      router.events.off("hashChangeStart", handleRouteChange);
    };
  }, [router]);

  return (
    <HStack as="nav" spacing="1rem" alignItems="stretch">
      <NextLink href={generateFrontPath({ id: "@front.home" })} passHref>
        <Box as="a" onFocus={() => setActivePanelId(null)}>
          <Image
            src={generateFrontPath({
              id: "@front.internal-assets",
              parameters: { filePath: "logo-full-horizontal.svg" },
              includeBasePath: true,
            })}
            alt={intl.formatMessage({ defaultMessage: "ALL IN ONE" })}
            w="259px"
            py="0.625rem"
          />
        </Box>
      </NextLink>

      <HStack spacing="0" flexGrow={1}>
        {menu?.items.map((menuItem, index) => (
          <MainMenuItem
            menuItem={menuItem}
            panelId={index}
            isActive={activePanelId === index}
            setActivePanelId={setActivePanelId}
            key={index}
          />
        ))}
      </HStack>

      <NextLink href={generateFrontPath({ id: "@front.contact" })} passHref>
        <Center
          as="a"
          h="auto"
          backgroundColor="brandPrimary.400"
          color="#fff"
          px="16px">
          <Text as="span" fontSize="1rem" color="inherit">
            <FormattedMessage defaultMessage="Nous contacter" />
          </Text>
        </Center>
      </NextLink>
    </HStack>
  );
});
