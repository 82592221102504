import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Stack,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { usePreferences } from "../../contexts/Preferences";

/**
 * Ce composant empêche l'initialisation du site si aucun environment ne correspond au domaine.
 * @typedef {object} Props
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
export function EnvironmentRequired({ children }) {
  const { bestEnvironment } = usePreferences();

  const intl = useIntl();

  if (!bestEnvironment) {
    return (
      <Alert status="warning">
        <AlertIcon />

        <Stack>
          <AlertTitle>
            {intl.formatMessage({
              defaultMessage: "Aucun environment ne correspond à ce domaine.",
            })}
          </AlertTitle>

          <AlertDescription>
            {intl.formatMessage({
              defaultMessage:
                "Ajoutez ce domaine à l'environnement correspondant dans l'interface d'administration.",
            })}
          </AlertDescription>
        </Stack>
      </Alert>
    );
  }

  return children;
}
