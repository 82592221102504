import useLocale from "@raiden/library/hooks/useLocale";
import Head from "next/head";

/**
 * @param {string} title
 */
function formatTitle(title) {
  return title ? `${title} - All In One` : "All In One";
}

/**
 * @typedef {Object} Props
 * @property {string} [title]
 * @property {string} [description]
 * @property {string} [keywords]
 * @property {boolean} [noIndex]
 * @property {(locale: string) => string} [links]
 * @property {(locale: string) => string} [canonicalLink]
 * @property {(locale: string) => string} [alternateLinks]
 *
 * @param {Props} props
 */
function Seo({
  title,
  description,
  keywords,
  noIndex,
  links,
  canonicalLink,
  alternateLinks,
}) {
  const { locale: _currentLocale, locales = [], defaultLocale } = useLocale();

  const currentLocale =
    _currentLocale !== "default" ? _currentLocale : defaultLocale;
  const otherLocales = locales.filter(
    (locale) => locale !== "default" && locale !== currentLocale,
  );

  return (
    <Head>
      {typeof title === "string" && ( // necessary to catch empty string
        <title key="title">{formatTitle(title)}</title>
      )}

      {description && (
        <meta name="description" content={description} key="description" />
      )}

      {keywords && <meta name="keywords" content={keywords} key="keywords" />}

      {noIndex && <meta name="robots" content="noindex" key="noindex" />}

      {(links || canonicalLink) && currentLocale && (
        <link
          rel="canonical"
          href={(links ?? canonicalLink)?.(currentLocale)}
          key="canonical"
        />
      )}

      {(links || alternateLinks) &&
        otherLocales.map?.((locale, index) => (
          <link
            rel="alternate"
            href={(links ?? alternateLinks)?.(locale)}
            hrefLang={locale}
            key={index}
          />
        ))}
    </Head>
  );
}

export default Seo;
