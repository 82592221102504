export const BUTTON_STYLE_CONFIG = {
  sizes: {
    xs: {
      borderRadius: "none",
    },
    sm: {
      borderRadius: "none",
    },
    md: {
      borderRadius: "none",
    },
    lg: {
      borderRadius: "none",
    },
  },
};
