import { useIntl } from "react-intl";
import Seo from "..";

function DefaultSeo() {
  const intl = useIntl();

  return (
    <Seo
      title=""
      description={intl.formatMessage({
        defaultMessage:
          "Économies, gestion et suivi de tous vos contrats et abonnements, à vie.",
      })}
    />
  );
}

export default DefaultSeo;
