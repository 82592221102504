import { Box, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library/hooks/useTranslate";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import NextLink from "next/link";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../helpers/menus/getMenuItemAdapterValues";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/Configuration").ConfigurationMenuItem} menuItem
 *
 * @param {Props} props
 */
export function MainMenuItem({ menuItem }) {
  const translate = useTranslate();

  if (getMenuItemAdapterType({ menuItem }) === undefined) {
    return null;
  }

  const { title, href, target } = getMenuItemAdapterValues({
    menuItem,
    translate,
  });

  return (
    <ConditionalWrapper
      hasWrapper={href !== undefined}
      wrapper={(children) => (
        <NextLink href={href ?? ""} passHref>
          {children}
        </NextLink>
      )}>
      <Box
        as={href !== undefined ? "a" : undefined}
        target={target}
        display="block"
        fontSize="1.125rem"
        fontWeight={400}
        lineHeight="1.5rem"
        color="brandPrimary.500"
        position="relative"
        py="0.75rem"
        px="1rem"
        whiteSpace="nowrap">
        <Text as="span" color="brandPrimary.500">
          {title}
        </Text>
      </Box>
    </ConditionalWrapper>
  );
}
