import { Box, Flex } from "@chakra-ui/react";
import { useRef } from "react";
import { HeaderContextProvider } from "../../../contexts/Header/Header";
import { Footer } from "./Footer";
import { HeaderDesktop } from "./HeaderDesktop";
import { HeaderMobile } from "./HeaderMobile";
import { HeaderHeightCompute } from "./HeaderHeightCompute";

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children
 */
/**
 * @param {Props} props
 */
export function PublicLayout({ children }) {
  const ref = useRef(/** @type {HTMLDivElement | null} */ (null));

  return (
    <HeaderContextProvider>
      <Flex flexDir="column" justifyContent="stretch" minH="100vh">
        {typeof window !== "undefined" && (
          <HeaderHeightCompute headerRef={ref} />
        )}

        <Box ref={ref} position="sticky" zIndex={1} top="0" flexShrink={0}>
          <HeaderDesktop />

          <HeaderMobile />
        </Box>

        <Flex flexDir="column" justifyContent="stretch" flexGrow={1}>
          {children}
        </Flex>

        <Box flexShrink={0}>
          <Footer />
        </Box>
      </Flex>
    </HeaderContextProvider>
  );
}
