import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { defineMessage } from "react-intl";

/**
 * @typedef {object} SocialLink
 * @property {string} src
 * @property {import("react-intl").MessageDescriptor} alt
 * @property {string} href
 * @property {string} label
 */

/**
 * @type {SocialLink[]}
 */
export const _SOCIAL_LINKS = [
  {
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "icons/facebook.svg" },
    }),
    alt: defineMessage({ defaultMessage: "facebook" }),
    href: "", //TODO href
    label: "@allinoneoriginal",
  },
  {
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "icons/linkedin.svg" },
    }),
    alt: defineMessage({ defaultMessage: "linkedin" }),
    href: "", //TODO href
    label: "@allinoneoriginal",
  },
  // {
  //   src: generateFrontPath({
  //     id: "@front.internal-assets",
  //     parameters: { filePath: "icons/send.svg" },
  //   }),
  //   alt: defineMessage({ defaultMessage: "email" }),
  //   href: "mailto:contact@allinoneoriginal.com",
  //   label: "contact@allinoneoriginal.com",
  // },
];

export const SOCIAL_LINKS = _SOCIAL_LINKS.filter(
  (socialLink) => socialLink.href,
);

/**
 * @typedef {"whenever" | "pickDate"} ContactTypeValue
 */

/**
 * @typedef {object} ContactType
 * @property {ContactTypeValue} value
 * @property {import("react-intl").MessageDescriptor} label
 */

/** @type {ContactType[]} */
export const CONTACT_TYPES_LIST = [
  {
    value: "whenever",
    label: defineMessage({ defaultMessage: "N'importe quand" }),
  },
  {
    value: "pickDate",
    label: defineMessage({
      defaultMessage: "Je choisis une date",
    }),
  },
];

/**
 * @typedef {"morning" | "afternoon" | "evening" | "whenever"} ContactTimeOfDayValue
 */

/**
 * @typedef {object} ContactTimeOfDay
 * @property {ContactTimeOfDayValue} value
 * @property {import("react-intl").MessageDescriptor} label
 */

/** @type {ContactTimeOfDay[]} */
export const CONTACT_TIME_OF_DAY_LIST = [
  {
    value: "morning",
    label: defineMessage({ defaultMessage: "Matin" }),
  },
  {
    value: "afternoon",
    label: defineMessage({ defaultMessage: "Après-midi" }),
  },
  {
    value: "evening",
    label: defineMessage({
      defaultMessage: "Début de soirée (18h-20h)",
    }),
  },
  {
    value: "whenever",
    label: defineMessage({ defaultMessage: "N'importe quand" }),
  },
];
