import { Box, Center, DarkMode, Flex, HStack, Text } from "@chakra-ui/react";
import { useConfiguration } from "@raiden/library/hooks/useConfiguration";
import { memo } from "react";
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import { getFormattedPhoneNumber } from "../../helpers/phoneNumber/getFormattedPhoneNumber";
import { Icon } from "../Icon";

export const CallButton = memo(
  /**
   * @typedef {Object} Props
   * @property {import("react-intl").MessageDescriptor} [message]
   */
  /**
   * @param {Props} props
   */
  function CallButton({
    message = defineMessage({ defaultMessage: "{phone}" }),
  }) {
    const intl = useIntl();

    const { configuration } = useConfiguration();

    const phoneNumber = configuration.environments[0]?.phone_number;

    return (
      <Center
        as="a"
        href={`tel:${phoneNumber}`}
        display="inline-block"
        backgroundColor="brandPrimary.400"
        color="#fff"
        borderRadius="none"
        h="auto"
        pr="1rem"
        py="1rem">
        <HStack>
          <Box transform="rotate(40deg)">
            <Icon icon="ms_call" color="#fff" size="32px" />
          </Box>

          <Center>
            <DarkMode>
              <Flex flexDir="column">
                <Text fontSize="1.5rem" fontWeight={400} lineHeight="normal">
                  {intl.formatMessage(message, {
                    phone: getFormattedPhoneNumber(phoneNumber),
                    br: <br />,
                  })}
                </Text>

                <Text fontSize="1rem" lineHeight="1rem">
                  <FormattedMessage defaultMessage="appel gratuit" />
                </Text>
              </Flex>
            </DarkMode>
          </Center>
        </HStack>
      </Center>
    );
  },
);
