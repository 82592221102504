import { Box, Link, Stack, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library/hooks/useTranslate";
import NextLink from "next/link";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../helpers/menus/getMenuItemAdapterValues";

/**
 * @param {object} params
 * @param {string | null} params.role
 * @returns {import("@chakra-ui/react").TextProps}
 */
function getMenuItemProps({ role }) {
  switch (role) {
    case "category": {
      return {
        variant: "h3",
        pb: "1.5rem",
      };
    }

    default: {
      return {
        fontSize: "1.125rem",
        fontWeight: 400,
        lineHeight: "1.5rem",
      };
    }
  }
}

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/Configuration").ConfigurationMenuItem} menuItem
 *
 * @param {Props} props
 */
export function MenuItem({ menuItem }) {
  const translate = useTranslate();

  if (getMenuItemAdapterType({ menuItem }) === undefined) {
    return null;
  }

  const { title, href, target, role } = getMenuItemAdapterValues({
    menuItem,
    translate,
  });

  const item = <Text {...getMenuItemProps({ role })}>{title}</Text>;

  return (
    <Box>
      {href !== undefined ? (
        <NextLink href={href} passHref>
          <Link as="a" color="#fff" target={target}>
            {item}
          </Link>
        </NextLink>
      ) : (
        <Box>{item}</Box>
      )}

      {menuItem.children.length > 0 && (
        <Stack spacing="0.5rem">
          {menuItem.children.map((menuItem, index) => {
            return <MenuItem menuItem={menuItem} key={index} />;
          })}
        </Stack>
      )}
    </Box>
  );
}
