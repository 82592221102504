import { AnimatePresence } from "framer-motion";
import { Router } from "next/router";
import { memo, useCallback, useEffect, useState } from "react";
import {
  COOKIES_KEY_LOCALSTORAGE,
  COOKIES_STATES,
} from "../../constants/cookies";
import {
  getGoogleAnalyticsScript,
  getGoogleTagManagerScript,
  trackPageView,
} from "../../helpers/googleTracking";
import CookiesModal from "./CookiesModal";

const GOOGLE_ANALYTICS_KEY = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY;

const GOOGLE_TAG_MANAGER_KEY = process.env.NEXT_PUBLIC_TAG_MANAGER_KEY;

function CookiesConsent() {
  const [cookiesState, setCookiesState] = useState(COOKIES_STATES.UNKNOWN);

  const [isClosedByUser, setIsClosedByUser] = useState(false);

  useEffect(() => {
    try {
      const cookiesState = localStorage.getItem(COOKIES_KEY_LOCALSTORAGE);
      const clientChoice = cookiesState
        ? JSON.parse(cookiesState)
        : COOKIES_STATES.UNKNOWN;
      setCookiesState(clientChoice);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const handleAccept = useCallback(() => {
    setCookiesState(COOKIES_STATES.ACCEPTED);
    localStorage.setItem(
      COOKIES_KEY_LOCALSTORAGE,
      JSON.stringify(COOKIES_STATES.ACCEPTED),
    );
  }, []);

  const handleRefuse = useCallback(() => {
    setCookiesState(COOKIES_STATES.REFUSED);
    localStorage.setItem(
      COOKIES_KEY_LOCALSTORAGE,
      JSON.stringify(COOKIES_STATES.REFUSED),
    );
  }, []);

  const handleClose = useCallback(() => {
    setIsClosedByUser(true);
  }, []);

  useEffect(() => {
    if (
      (GOOGLE_ANALYTICS_KEY || GOOGLE_TAG_MANAGER_KEY) &&
      cookiesState === COOKIES_STATES.ACCEPTED
    ) {
      Router.events.on("routeChangeComplete", (path) => {
        trackPageView(
          window.location.pathname + window.location.search,
          GOOGLE_ANALYTICS_KEY || GOOGLE_TAG_MANAGER_KEY,
        );
      });
    }
  }, [cookiesState]);

  return (
    <>
      {GOOGLE_TAG_MANAGER_KEY &&
        cookiesState === COOKIES_STATES.ACCEPTED &&
        getGoogleTagManagerScript(GOOGLE_TAG_MANAGER_KEY)}

      {GOOGLE_ANALYTICS_KEY &&
        cookiesState === COOKIES_STATES.ACCEPTED &&
        getGoogleAnalyticsScript(GOOGLE_ANALYTICS_KEY)}

      <AnimatePresence>
        {cookiesState === COOKIES_STATES.UNKNOWN && !isClosedByUser && (
          <CookiesModal
            accept={handleAccept}
            refuse={handleRefuse}
            close={handleClose}
          />
        )}
      </AnimatePresence>
    </>
  );
}

export default memo(CookiesConsent);
