/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const SELECT_STYLE_CONFIG = {
  parts: ["addon", "field", "element", "input"],
  defaultProps: {
    variant: "flushed",
    focusBorderColor: "brandPrimary.500",
  },
  // variants: {
  //   flushed: {
  //     field: {
  //       bg: "#fff",
  //       background: "#fff",
  //       backgroundColor: "#fff",
  //       _placeholder: {
  //         color: "var(--chakra-colors-brandPrimary-400)",
  //       },
  //     },
  //   },
  // },
};
