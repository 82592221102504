import { Box, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library/hooks/useTranslate";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import NextLink from "next/link";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../helpers/menus/getMenuItemAdapterValues";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/Configuration").ConfigurationMenuItem} menuItem
 * @property {{ id: number, slug: string}} [postCategoryData]
 *
 * @param {Props} props
 */
export function SubMenuItem({ menuItem, postCategoryData }) {
  const translate = useTranslate();

  const type = getMenuItemAdapterType({ menuItem });

  if (type === undefined) {
    return null;
  }

  const { title, href, target } = getMenuItemAdapterValues({
    menuItem,
    translate,
    postCategoryData,
  });

  return (
    <Box>
      <ConditionalWrapper
        hasWrapper={href !== undefined}
        wrapper={(children) => (
          <NextLink href={href ?? ""} passHref>
            {children}
          </NextLink>
        )}>
        <Box
          as={href !== undefined ? "a" : undefined}
          target={target}
          color="brandPrimary.400"
          _hover={{ textDecoration: "underline" }}>
          <Text as="span" color="brandPrimary.400">
            {title}
          </Text>
        </Box>
      </ConditionalWrapper>

      {menuItem.children.map((menuItem, index) => {
        return <SubMenuItem menuItem={menuItem} key={index} />;
      })}
    </Box>
  );
}
