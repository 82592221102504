import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library/hooks/useTranslate";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import NextLink from "next/link";
import { memo, useMemo } from "react";
import CenteredContent from "../../../../components/CenteredContent";
import { Icon } from "../../../../components/Icon";
import { Z_INDEX } from "../../../../constants/zIndex";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../helpers/menus/getMenuItemAdapterValues";
import { SubMenuItem } from "./SubMenuItem";

export const MainMenuItem = memo(
  /**
   * @typedef {Object} Props
   * @property {import("@raiden/library/types/Configuration").ConfigurationMenuItem} menuItem
   * @property {number} panelId
   * @property {boolean} isActive
   * @property {(panelId: number | null) => void} setActivePanelId
   */
  /**
   * @param {Props} props
   */
  function MainMenuItem({ menuItem, panelId, isActive, setActivePanelId }) {
    const translate = useTranslate();

    const type = getMenuItemAdapterType({ menuItem });

    const postCategoryData = useMemo(() => {
      const id = menuItem.object_id;
      const slug = translate(menuItem.object?.slug);
      if (id && slug) {
        return {
          id,
          slug,
        };
      }
    }, [menuItem.object?.slug, menuItem.object_id, translate]);

    if (type === undefined) {
      return null;
    }

    const {
      title,
      href: _href,
      target,
      icon,
    } = getMenuItemAdapterValues({
      menuItem,
      translate,
    });

    const href = type === "page_category" ? undefined : _href;

    return (
      <Flex
        onMouseEnter={() => setActivePanelId(panelId)}
        onMouseLeave={() => setActivePanelId(null)}
        onFocus={() => setActivePanelId(panelId)}
        alignItems="strech"
        h="full"
        role="group">
        <ConditionalWrapper
          hasWrapper={href !== undefined}
          wrapper={(children) => (
            <NextLink href={href ?? ""} passHref>
              {children}
            </NextLink>
          )}>
          <Box
            tabIndex={0}
            as={href !== undefined ? "a" : undefined}
            target={target}
            fontSize="1.125rem"
            fontWeight={400}
            lineHeight="1.5rem"
            color="brandPrimary.500"
            px="0.625rem"
            position="relative"
            whiteSpace="nowrap">
            <HStack spacing="0.25rem" h="full">
              {icon && (
                <Icon icon={icon} size="2rem" color="brandPrimary.300" />
              )}

              <HStack spacing="0" h="full">
                <Text as="span" color="brandPrimary.500">
                  {title}
                </Text>

                {menuItem.children.length > 0 && (
                  <Icon icon="ms_expand_more" size="1.5rem" color="#1B1F32" />
                )}
              </HStack>
            </HStack>

            <Box
              visibility="hidden"
              position="absolute"
              zIndex={Z_INDEX.HEADER_DROP_DOWN_OVERLAY}
              top="100%"
              left="0.625rem"
              right="0.625rem"
              h="3px"
              backgroundColor="brandPrimary.400"
              borderRadius="full"
              opacity={0}
              transition="all .25s ease"
              _groupHover={{
                opacity: 1,
                visibility: "visible",
              }}
            />
          </Box>
        </ConditionalWrapper>

        {menuItem.children.length > 0 && (
          <Box
            visibility={isActive ? "visible" : "hidden"}
            position="absolute"
            top="100%"
            left="0"
            right="0"
            backgroundColor="#fff"
            borderBottomWidth="1px"
            borderTop="1px solid #E5E9FA"
            zIndex={isActive ? Z_INDEX.HEADER_DROP_DOWN : "auto"}>
            <Box pl="1rem">
              <CenteredContent maxWidth="calc(1320px + 1rem)">
                <Box py="1.5rem" pl="calc(259px)" maxW="1320px">
                  <HStack>
                    {menuItem.icon && (
                      <Icon
                        icon={menuItem.icon}
                        size="8rem"
                        color="brandPrimary.300"
                      />
                    )}

                    <Box flexGrow={1}>
                      <Text
                        fontSize="1.75rem"
                        fontWeight={400}
                        lineHeight="normal"
                        color="brandPrimary.500"
                        pb="0.75rem"
                        borderBottomWidth="1px"
                        borderColor="brandPrimary.300">
                        {title}
                      </Text>

                      <Stack spacing="0.5rem" mt="0.75rem">
                        {menuItem.children.map((menuItem, index) => {
                          return (
                            <SubMenuItem
                              menuItem={menuItem}
                              postCategoryData={postCategoryData}
                              key={index}
                            />
                          );
                        })}
                      </Stack>
                    </Box>
                  </HStack>
                </Box>
              </CenteredContent>
            </Box>
          </Box>
        )}
      </Flex>
    );
  },
);
