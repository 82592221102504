import { mode } from "@chakra-ui/theme-tools";

export const TEXT_THEME = {
  baseStyle(props) {
    const { colorScheme } = props;
    return {
      color: colorScheme
        ? mode(`${colorScheme}.600`, `${colorScheme}.300`)(props)
        : mode("brandPrimary.500", "#fff")(props),
    };
  },
  defaultProps: {
    variant: "text-default",
  },
  variants: {
    h1: {
      fontSize: {
        base: "2.5rem",
        md: "3rem",
      },
      fontWeight: 600,
      lineHeight: "normal",
    },
    h2: {
      fontSize: {
        base: "2rem",
        md: "1.75rem",
      },
      fontWeight: 500,
      lineHeight: "normal",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
    h4: {
      fontSize: "1.4375rem",
      fontWeight: 300,
      lineHeight: "normal",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 300,
      lineHeight: "normal",
    },
    h6: {
      fontSize: "1.125rem",
      fontWeight: 300,
      lineHeight: "normal",
    },
    "text-default": {
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    "text-small": {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "1.3125rem",
    },
    "text-medium": {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.3125rem",
    },
    "text-large": {
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
};
