import { defineMessage } from "react-intl";

export const MATERIAL_SYMBOLS_CODEPOINTS_URL =
  "https://raw.githubusercontent.com/google/material-design-icons/master/variablefont/MaterialSymbolsOutlined%5BFILL%2CGRAD%2Copsz%2Cwght%5D.codepoints";

/** @typedef {"materialSymbols" | "internal"} IconTypeValue */

/**
 * @typedef {{name: import("react-intl").MessageDescriptor, type: IconTypeValue, prefix: string}} IconType
 */

/** @type {IconType[]} */
export const ICON_TYPES_LIST = [
  {
    name: defineMessage({ defaultMessage: "Material Symbols" }),
    type: "materialSymbols",
    prefix: "ms_",
  },
  {
    name: defineMessage({ defaultMessage: "Icônes internes" }),
    type: "internal",
    prefix: "sf_",
  },
];

export const ICON_TYPES_MAP = new Map(
  ICON_TYPES_LIST.map((item) => [item.type, item]),
);

export const ICON_TYPES_PREFIX_MAP = new Map(
  ICON_TYPES_LIST.map((item) => [item.prefix, item]),
);

/**
 * @typedef {{icon: string, label: import("react-intl").MessageDescriptor, element: JSX.Element}} InternalIcon
 */

/** @type {InternalIcon[]} */
export const INTERNAL_ICONS_LIST = [
  {
    icon: "sf_search",
    element: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 -960 960 960">
        <path
          fill="currentColor"
          d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"
        />
      </svg>
    ),
    label: defineMessage({ defaultMessage: "Rechercher" }),
  },
  {
    icon: "sf_facebook",
    element: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 30 30">
        <path
          fill="currentColor"
          d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z"></path>
      </svg>
    ),
    label: defineMessage({ defaultMessage: "Facebook" }),
  },
];

export const INTERNAL_ICONS_MAP = new Map(
  INTERNAL_ICONS_LIST.map((item) => [item.icon, item]),
);
