import {
  Box,
  DarkMode,
  HStack,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useConfiguration } from "@raiden/library/hooks/useConfiguration";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Icon } from "../../../../components/Icon";
import { getFormattedPhoneNumber } from "../../../../helpers/phoneNumber/getFormattedPhoneNumber";
import { useScrollBlocker } from "../../../../hooks/useScrollBlocker";
import { MenuMobile } from "./MenuMobile";

export function HeaderMobile() {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const { block, unblock } = useScrollBlocker();

  const isMobile = useBreakpointValue({ base: true, xl: false });

  const { configuration } = useConfiguration();

  const phoneNumber = configuration.environments[0]?.phone_number;

  const router = useRouter();

  useEffect(() => {
    if (!isMobile) {
      unblock();
    }
  }, [isMobile, unblock]);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      unblock();
      setIsOpen(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router, unblock]);

  const onOpen = useCallback(() => {
    block();
    setIsOpen(true);
  }, [block]);

  const onClose = useCallback(() => {
    unblock();
    setIsOpen(false);
  }, [unblock]);

  const onToggle = useCallback(() => {
    isOpen ? onClose() : onOpen();
  }, [isOpen, onClose, onOpen]);

  return (
    <>
      <Box display={{ base: "block", xl: "none" }}>
        <Box backgroundColor="brandPrimary.400">
          <DarkMode>
            <HStack as="a" href={`tel:${phoneNumber}`} px="1rem" py=".25rem">
              <Icon icon="ms_call" color="#fff" size="21px" />

              <Text>
                {`${getFormattedPhoneNumber(
                  phoneNumber,
                )} - ${intl.formatMessage({
                  defaultMessage: "appel gratuit",
                })}`}
              </Text>
            </HStack>
          </DarkMode>
        </Box>

        <HStack
          backgroundColor="#fff"
          borderBottomWidth="1px"
          py="0.5rem"
          px="1rem">
          <Box flexGrow={1}>
            <NextLink href={generateFrontPath({ id: "@front.home" })} passHref>
              <Box as="a">
                <Image
                  src={generateFrontPath({
                    id: "@front.internal-assets",
                    parameters: { filePath: "logo-full-horizontal.svg" },
                    includeBasePath: true,
                  })}
                  alt={intl.formatMessage({ defaultMessage: "ALL IN ONE" })}
                  w="259px"
                  py="0.625rem"
                />
              </Box>
            </NextLink>
          </Box>

          <Box as="button" onClick={onToggle} w="64px" h="64px">
            <Icon
              icon={isOpen ? "ms_close" : "ms_menu"}
              size="64px"
              color="#1B1F32"
            />
          </Box>
        </HStack>
      </Box>

      <MenuMobile isOpen={isOpen} />
    </>
  );
}
