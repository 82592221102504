import { useDebounceCall } from "@raiden/library/hooks/useDebounceCall";
import { useCallback, useEffect } from "react";
import { useHeader } from "../../../hooks/useHeader";

/**
 * @typedef {Object} Props
 * @property {React.MutableRefObject<HTMLDivElement | null>} headerRef
 */
/**
 * @param {Props} props
 */
export function HeaderHeightCompute({ headerRef }) {
  const { setHeight } = useHeader();

  const updateHeight = useCallback(() => {
    const header = headerRef.current;
    if (header) {
      const height = `${header.offsetHeight}px`;
      setHeight(height);
    }
  }, [headerRef, setHeight]);

  const debouncedUpdateHeight = useDebounceCall(updateHeight, 100);

  useEffect(() => {
    updateHeight();
  }, [updateHeight]);

  // add window event listener resize to update height
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleResize = () => {
      debouncedUpdateHeight();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [headerRef, setHeight, debouncedUpdateHeight]);

  return null;
}
