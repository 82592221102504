import { useConfigurationMenu } from "@raiden/library/hooks/useConfigurationMenu";
import { memo } from "react";
import { MenuItem } from "./MenuItem";
import { RGrid } from "@raiden/library/components/RGrid";

export const Menu = memo(function Menu() {
  const { menu } = useConfigurationMenu({ alias: "footer" });

  return (
    <RGrid minCellWidth="min(300px, 100%)" gridGap="2rem" w="full">
      {menu?.items?.map((menuItem, index) => {
        return <MenuItem menuItem={menuItem} key={index} />;
      })}
    </RGrid>
  );
});
