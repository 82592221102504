import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { useConfigurationMenu } from "@raiden/library/hooks/useConfigurationMenu";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { FormattedMessage } from "react-intl";
import { CallButton } from "../../../../components/CallButton";
import { useHeader } from "../../../../hooks/useHeader";
import { MainMenuItem } from "./MainMenuItem";

/**
 * @typedef {Object} Props
 * @property {boolean} isOpen
 */
/**
 * @param {Props} props
 */
export function MenuMobile({ isOpen }) {
  const { menu } = useConfigurationMenu({ alias: "header" });

  const { height } = useHeader();

  return (
    <Box
      as="nav"
      visibility={{ base: isOpen ? "visible" : "hidden", xl: "hidden" }}
      position="fixed"
      top={height}
      maxHeight={`calc(100vh - ${height})`}
      overflowY="auto"
      right="0"
      bottom="0"
      left="0"
      opacity={isOpen ? { base: 1, xl: 0 } : 0}
      transition="all 0.25s ease"
      backgroundColor={isOpen ? "#fff" : "transparent"}
      transform={`translateY(${isOpen ? "0" : "10%"})`}>
      <Stack spacing="1rem" mt="1rem" transition="all 0.25s ease">
        <Box>
          {menu?.items.map((menuItem, index) => (
            <MainMenuItem menuItem={menuItem} key={index} />
          ))}
        </Box>

        <Stack alignSelf="flex-start" spacing="1rem" px="1rem">
          <NextLink href={generateFrontPath({ id: "@front.contact" })} passHref>
            <Button
              as="a"
              variant="outline"
              borderColor="brandPrimary.400"
              w="full"
              h="5rem"
              px="1.5rem">
              <Text fontSize="1.5rem" fontWeight={400} lineHeight="normal">
                <FormattedMessage defaultMessage="Nous contacter" />
              </Text>
            </Button>
          </NextLink>

          <CallButton />
        </Stack>
      </Stack>
    </Box>
  );
}
