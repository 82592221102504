// polifills
import "../polyfills";
import intlPolyfills from "../polyfills/intl";

// imports
import { ChakraProvider, cookieStorageManager } from "@chakra-ui/react";
import { ErrorBoundaryDegraded } from "@raiden/library/components/ErrorBoundaryDegraded";
import { COOKIES_NAME_LIST } from "@raiden/library/constants/cookies";
import { frontBaseUri } from "@raiden/library/constants/routers/front";
import AuthProvider from "@raiden/library/contexts/Auth";
import { ConfigurationProvider } from "@raiden/library/contexts/Configuration";
import { MaintenanceModeProvider } from "@raiden/library/contexts/MaintenanceMode";
import { PreferencesProvider } from "@raiden/library/contexts/Preferences";
import { TranslationMessagesProvider } from "@raiden/library/contexts/TranslationMessages";
import {
  nextGetConfiguration,
  nextGetCookies,
  nextGetUser,
} from "@raiden/library/helpers/next";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import cookie from "cookie";
import "focus-visible/dist/focus-visible";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "../components/ErrorBoundary";
import ProgressBar from "../components/ProgressBar";
import DefaultSeo from "../components/Seo/DefaultSeo";
import theme from "../constants/theme";
import CookiesConsent from "../containers/CookiesConsent";
import getTranslationMessages from "../libraries/utils/getTranslationMessages";
import { EnvironmentRequired } from "@raiden/library/components/EnvironmentRequired";
import { PublicLayout } from "../containers/Layouts/PublicLayout";

const ENVIRONMENT_IDS =
  process.env.NEXT_PUBLIC_FRONT_HOST !== undefined
    ? [process.env.NEXT_PUBLIC_FRONT_HOST]
    : [];

const SWR_CONFIG = {
  revalidateOnFocus: false,
  errorRetryCount: 0,
  refreshWhenOffline: false,
};

const CONFIGURATION_FIELDS = [
  ...["globals.covers", "environments", "menus", "posts"],
  // we add the `auth` field only if auth is enabled
  ...(process.env.NEXT_PUBLIC_USE_AUTH === "true" ? ["auth"] : []),
];

const CONFIGURATION_URL = generateApiUrl({
  id: "@api.configuration",
  query: {
    fields: CONFIGURATION_FIELDS,
    environment_id: ENVIRONMENT_IDS,
  },
});

/**
 * @typedef {object} Props
 * @property {import("../types/Page").PageInitialProps} [initialProps]
 * @property {Record<string, any>} cookies
 * @property {string} [locale]
 * @property {object} initialTranslationMessages
 * @property {import("@raiden/library/types/Configuration").Configuration} [configuration]
 * @property {import("@raiden/library/types/User").User} [user]
 *
 * @param {Props & { Component: import("react").FC<import("../types/Page").PageInitialProps>}} props
 */
export default function App({
  Component,
  initialProps,
  cookies,
  locale,
  initialTranslationMessages,
  configuration,
  user,
}) {
  const colorModeManager = cookieStorageManager(
    cookie.serialize("chakra-ui-color-mode", cookies["chakra-ui-color-mode"]),
  );

  return (
    <ErrorBoundaryDegraded>
      <SWRConfig value={SWR_CONFIG}>
        <TranslationMessagesProvider
          initialTranslationMessages={initialTranslationMessages}>
          {({ translationMessages }) => (
            <>
              {locale && (
                <IntlProvider
                  locale={locale}
                  defaultLocale={process.env.NEXT_PUBLIC_DEFAULT_LOCALE}
                  messages={translationMessages}>
                  <MaintenanceModeProvider>
                    <AuthProvider
                      initialUser={user}
                      isEnabled={process.env.NEXT_PUBLIC_USE_AUTH === "true"}>
                      <ConfigurationProvider
                        url={CONFIGURATION_URL}
                        initialConfiguration={configuration}>
                        <PreferencesProvider>
                          <ChakraProvider
                            theme={theme}
                            colorModeManager={colorModeManager}>
                            <ErrorBoundary>
                              <EnvironmentRequired>
                                <DefaultSeo />

                                <PublicLayout>
                                  <Component {...initialProps} />
                                </PublicLayout>

                                <ProgressBar />

                                <CookiesConsent />
                              </EnvironmentRequired>
                            </ErrorBoundary>
                          </ChakraProvider>
                        </PreferencesProvider>
                      </ConfigurationProvider>
                    </AuthProvider>
                  </MaintenanceModeProvider>
                </IntlProvider>
              )}
            </>
          )}
        </TranslationMessagesProvider>
      </SWRConfig>
    </ErrorBoundaryDegraded>
  );
}

/**
 * @param {object} appProps
 * @param {import("next").NextComponentType<import("../types/AppContext").PageContext, import("../types/Page").PageInitialProps>} appProps.Component
 * @param {import("next/dist/shared/lib/utils").AppTreeType} appProps.AppTree
 * @param {import("next").NextPageContext} appProps.ctx
 * @param {import("next/router").default} appProps.router
 */
App.getInitialProps = async function (appProps) {
  const {
    Component,
    router: { locale },
  } = appProps;

  const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

  const { cookies } = await nextGetCookies({
    req: appProps.ctx.req,
    whitelist: COOKIES_NAME_LIST.reduce(function (cookies, { id: cookieName }) {
      cookies[cookieName] = true;
      return cookies;
    }, {}),
  });

  // only fetch translation messages on the server on initial render
  const initialTranslationMessages =
    typeof window === "undefined" &&
    locale !== undefined &&
    defaultLocale !== undefined
      ? await getTranslationMessages(locale, defaultLocale)
      : null;

  // only fetch configuration on the server on initial render
  const configurationResponse =
    typeof window === "undefined"
      ? await nextGetConfiguration({
          cookies,
          baseUri: frontBaseUri,
          locale,
          req: appProps.ctx.req,
          environmentsIds: ENVIRONMENT_IDS,
          fields: CONFIGURATION_FIELDS,
        })
      : null;

  const configuration = configurationResponse?.configuration;

  let userData =
    process.env.NEXT_PUBLIC_USE_AUTH === "true"
      ? await nextGetUser({
          cookies,
          baseUri: frontBaseUri,
          locale,
          req: appProps.ctx.req,
        })
      : undefined;
  const user = userData?.user;

  const [initialProps] = await Promise.all([
    Component.getInitialProps?.({
      ...appProps.ctx,
      configuration: configuration,
    }),
    intlPolyfills(locale),
  ]);

  /** @type {Props} */
  const values = {
    initialProps,
    locale,
    cookies,
    initialTranslationMessages,
    configuration,
    user,
  };

  return values;
};
