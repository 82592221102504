/**
 * @typedef {"text" | "link" | "page" | "page_category" | "article" | undefined} MenuItemAdapterType
 */

import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";

/**
 * @typedef {object} MenuItemAdapterValues
 * @property {MenuItemAdapterType} type - The type of the menu item.
 * @property {string} title - The title of the menu item.
 * @property {string | undefined} href - The URL of the menu item.
 * @property {"_blank" | undefined} target - The target attribute for the menu item link.
 * @property {string | null} role - The role of the menu item.
 * @property {string | null} icon - The icon of the menu item.
 * @property {import("@raiden/library/types/Configuration").ConfigurationMenuItem[]} children - The children menu items.
 */

/**
 * Get the href value for a menu item based on its type.
 *
 * @param {object} params - The parameters for getting the href.
 * @param {MenuItemAdapterType} params.type - The type of the menu item.
 * @param {import("@raiden/library/types/Configuration").ConfigurationMenuItem} params.menuItem - The menu item object.
 * @param {(obj: {[key: string]: string;} | undefined) => string | undefined} params.translate - The translation function.
 *
 * @returns {string | undefined} - The href value for the menu item.
 */
function getMenuItemAdapterHref({ type, menuItem, translate }) {
  if (type === "text") {
    return undefined;
  }
  if (type === "link") {
    const url = translate(menuItem.url);
    const urlWithoutQuery = url?.split("?")[0];
    if (!url?.startsWith("http") && urlWithoutQuery?.includes(".")) {
      return `https://${url}`;
    }
    return url;
  }
  if (type === "page") {
    return generateFrontPath({
      id: "@front.page",
      parameters: {
        postSlug: `${menuItem.object_id}-${translate(menuItem.object?.slug)}`,
      },
    });
  }
  if (type === "page_category") {
    return generateFrontPath({
      id: "@front.category",
      parameters: {
        categorySlug: `${menuItem.object_id}-${translate(
          menuItem.object?.slug,
        )}`,
      },
    });
  }
}

/**
 * Get the type of a menu item.
 *
 * @param {object} params - The parameters for getting the menu item type.
 * @param {import("@raiden/library/types/Configuration").ConfigurationMenuItem} params.menuItem - The menu item object.
 *
 * @returns {MenuItemAdapterType} - The type of the menu item.
 */
export function getMenuItemAdapterType({ menuItem }) {
  if (menuItem.type === "link" && Object.keys(menuItem.url).length === 0) {
    return "text";
  }
  if (menuItem.type === "link") {
    return "link";
  }
  if (
    menuItem.type === "object" &&
    menuItem.object_type === "post" &&
    menuItem.object?.type === "page"
  ) {
    return "page";
  }
  if (
    menuItem.type === "object" &&
    menuItem.object_type === "post_category" &&
    menuItem.object?.type === "page"
  ) {
    return "page_category";
  }
}

/**
 * Get the adapter values for a menu item.
 *
 * @param {object} params - The parameters for getting the menu item adapter values.
 * @param {import("@raiden/library/types/Configuration").ConfigurationMenuItem} params.menuItem - The menu item object.
 * @param {(obj: {[key: string]: string;} | undefined) => string | undefined} params.translate - The translation function.
 * @param {{ id: number, slug: string}} [params.postCategoryData]
 *
 * @returns {MenuItemAdapterValues} - The adapter values for the menu item.
 */
export function getMenuItemAdapterValues({
  menuItem,
  translate,
  postCategoryData,
}) {
  const type = getMenuItemAdapterType({ menuItem });

  let href = getMenuItemAdapterHref({ type, menuItem, translate });

  if (postCategoryData !== undefined && type === "page") {
    href = generateFrontPath({
      id: "@front.category",
      parameters: {
        categorySlug: `${postCategoryData.id}-${postCategoryData.slug}`,
      },
      fragment: `${menuItem.object_id}-${translate(menuItem.object?.slug)}`,
    });
  }

  return {
    type,
    title: translate(menuItem.title) ?? "-Titre manquant-",
    href,
    target: href?.startsWith("http") ? "_blank" : undefined,
    role: menuItem.role,
    icon: menuItem.icon,
    children: menuItem.children,
  };
}
