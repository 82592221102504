import { Box } from "@chakra-ui/react";
import CenteredContent from "../../../../components/CenteredContent";
import { MenuDesktop } from "./MenuDesktop";

export function HeaderDesktop() {
  return (
    <Box
      position="relative"
      display={{ base: "none", xl: "block" }}
      boxShadow="0px 0px 0px 1px #E5E9FA"
      backgroundColor="#fff">
      <Box pl="1rem">
        <CenteredContent maxWidth="calc(1320px + 1rem)">
          <Box maxW="1320px">
            <MenuDesktop />
          </Box>
        </CenteredContent>
      </Box>
    </Box>
  );
}
