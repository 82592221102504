import { Box } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {string} [maxWidth] css max-width property
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
function CenteredContent({ maxWidth = "1320px", children }) {
  return (
    <Box w="full" maxW={maxWidth} mx="auto">
      {children}
    </Box>
  );
}

export default CenteredContent;
