import { createContext, useState } from "react";

/**
 * @typedef {object} HeaderContextValue
 * @property {string} height
 * @property {import("react").Dispatch<import("react").SetStateAction<string>>} setHeight
 */
export const HeaderContext = createContext(
  /** @type {HeaderContextValue} */ ({
    height: "0px",
    setHeight: () => {},
  }),
);

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children
 */
/**
 * @param {Props} props
 */
export const HeaderContextProvider = ({ children }) => {
  const [height, setHeight] = useState("0px");

  return (
    <HeaderContext.Provider value={{ height, setHeight }}>
      {children}
    </HeaderContext.Provider>
  );
};
