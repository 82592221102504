import { Grid } from "@chakra-ui/react";
import { memo } from "react";

export const RGrid = memo(
  /**
   * @typedef {object} Props
   * @property {string} [minCellWidth] default value is "320px"
   * @property {React.ReactNode} [children]
   *
   * @param {Props & import("@chakra-ui/react").GridProps} props
   */
  function RGrid({ minCellWidth = "320px", children, ...otherProps }) {
    return (
      <Grid
        gridTemplateColumns={`repeat(auto-fill, minmax(min(${minCellWidth}, 100%), 1fr))`}
        gridGap="1rem"
        {...otherProps}>
        {children}
      </Grid>
    );
  },
);
