import {
  Box,
  Button,
  DarkMode,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import { CallButton } from "../../../../components/CallButton";
import CenteredContent from "../../../../components/CenteredContent";
import { SOCIAL_LINKS } from "../../../../constants/contact";
import { Menu } from "./Menu";
import { SocialLink } from "../../../../components/SocialLink";
import { useConfiguration } from "@raiden/library/hooks/useConfiguration";
import { POSTS_TYPE_VALUE_PAGE } from "@raiden/library/constants/posts";
import useTranslate from "@raiden/library/hooks/useTranslate";

export function Footer() {
  const intl = useIntl();

  const translate = useTranslate();

  const { configuration } = useConfiguration();

  const email = configuration.environments[0]?.email;

  const postLegalNotice = configuration.posts?.[POSTS_TYPE_VALUE_PAGE]?.find(
    (post) => post.alias === process.env.NEXT_PUBLIC_POST_ALIAS_LEGAL_NOTICE,
  );

  return (
    <DarkMode>
      <Box backgroundColor="brandPrimary.500" px="1rem" py="3.75rem">
        <CenteredContent>
          <Stack
            direction={{ base: "column", lg: "row" }}
            justify="center"
            spacing="2rem">
            <Stack>
              <Text variant="h3">
                <FormattedMessage defaultMessage="Un conseiller à votre service" />
              </Text>

              <Text>
                <FormattedMessage defaultMessage="Contactez-nous pour prendre rendez-vous" />
              </Text>
            </Stack>

            <Stack
              direction={{ base: "column", lg: "row" }}
              alignItems="center"
              spacing="2rem">
              <Box>
                <CallButton />
              </Box>

              <Text variant="text-large">
                <FormattedMessage defaultMessage="ou" />
              </Text>

              <Box>
                <NextLink
                  href={generateFrontPath({ id: "@front.contact" })}
                  passHref>
                  <Button
                    as="a"
                    variant="outline"
                    borderColor="#fff"
                    minW="260px"
                    h="5rem"
                    px="1.5rem">
                    <Text
                      fontSize="1.5rem"
                      fontWeight={400}
                      lineHeight="normal">
                      <FormattedMessage defaultMessage="Nous contacter" />
                    </Text>
                  </Button>
                </NextLink>
              </Box>
            </Stack>
          </Stack>
        </CenteredContent>
      </Box>

      <Box
        backgroundColor="brandPrimary.400"
        py="3.75rem"
        px="1rem"
        backgroundImage={generateFrontPath({
          id: "@front.internal-assets",
          parameters: { filePath: "filigrane-footer.svg" },
          includeBasePath: true,
        })}
        backgroundPosition="right"
        backgroundRepeat="no-repeat"
        backgroundSize="auto 100%">
        <CenteredContent>
          <Stack direction={{ base: "column", xl: "row" }} spacing="2rem">
            <Stack
              flexShrink={0}
              direction={{ base: "column", md: "row" }}
              spacing="7.875rem">
              <Box flexShrink={0}>
                <Image
                  src={generateFrontPath({
                    id: "@front.internal-assets",
                    parameters: { filePath: "logo-label.png" },
                    includeBasePath: true,
                  })}
                  alt={intl.formatMessage({ defaultMessage: "ALL IN ONE" })}
                />
              </Box>

              <Box>
                <Box>
                  <Text variant="h3" pb="1.5rem">
                    Alexis Lacroix
                  </Text>
                </Box>

                <Stack spacing="1rem" alignItems="flex-start" w="full">
                  {SOCIAL_LINKS.map((socialLink, index) => (
                    <SocialLink {...socialLink} key={index} />
                  ))}

                  {email && (
                    <SocialLink
                      src={generateFrontPath({
                        id: "@front.internal-assets",
                        parameters: { filePath: "icons/send.svg" },
                      })}
                      alt={defineMessage({ defaultMessage: "email" })}
                      href={`mailto:${email}`}
                      label={email}
                    />
                  )}
                </Stack>
              </Box>
            </Stack>

            <Menu />
          </Stack>
        </CenteredContent>
      </Box>

      <HStack
        justify="center"
        backgroundColor="brandPrimary.500"
        px="1rem"
        py="0.75rem">
        <Text
          fontSize="0.75rem"
          fontWeight={500}
          lineHeight="1.3125rem"
          color="brandPrimary.300">
          <FormattedMessage defaultMessage="© 2024 Allinone Original. Tous droits réservés." />
        </Text>

        {postLegalNotice && (
          <NextLink
            href={generateFrontPath({
              id: "@front.page",
              parameters: {
                postSlug: `${postLegalNotice.id}-${translate(
                  postLegalNotice.slug,
                )}`,
              },
            })}
            passHref>
            <Link
              fontSize="0.75rem"
              fontWeight={500}
              lineHeight="1.3125rem"
              color="brandPrimary.300"
              textDecoration="underline">
              <FormattedMessage defaultMessage="Mentions légales" />
            </Link>
          </NextLink>
        )}
      </HStack>
    </DarkMode>
  );
}
