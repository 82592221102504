import { HStack, Image, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { useIntl } from "react-intl";

/**
 * @typedef {Object} Props
 * @property {string} src
 * @property {import("react-intl").MessageDescriptor} alt
 * @property {string} href
 * @property {string} label
 * @property {string} [color]
 */
/**
 * @param {Props} props
 */
export function SocialLink({ src, alt, href, label, color }) {
  const intl = useIntl();

  return (
    <NextLink href={href} passHref>
      <HStack as="a" target="_blank" spacing="0.75rem" role="group">
        <Image src={src} alt={intl.formatMessage(alt)} />

        <Text
          fontSize="1.125rem"
          fontWeight={400}
          lineHeight="1.5rem"
          color={color}
          _groupHover={{ textDecoration: "underline" }}>
          {label}
        </Text>
      </HStack>
    </NextLink>
  );
}
