import { useCallback, useEffect, useRef } from "react";

export function useDebounceCall(callback, delay = 500) {
  const timeoutRef = useRef(/** @type {any} */ (null));
  const callbackRef = useRef(/** @type {Function} */ (callback));

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return useCallback(
    (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args);
      }, delay);
    },
    [delay],
  );
}
