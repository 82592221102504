/**
 * Formats a phone number.
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number.
 */
export function getFormattedPhoneNumber(phoneNumber) {
  return phoneNumber.replace(
    /(\+\d{2})\s?(\d{1})\s?(\d{2})\s?(\d{2})\s?(\d{2})\s?(\d{2})/g,
    "$1 $2 $3 $4 $5 $6",
  );
}
