import { extendTheme } from "@chakra-ui/react";
import { CHECKBOX_STYLE_CONFIG } from "./components/checkbox";
import { TEXT_THEME } from "./components/text";
import { BUTTON_STYLE_CONFIG } from "./components/button";
import { INPUT_STYLE_CONFIG } from "./components/input";
import { FORM_STYLE } from "./components/form";
import { SELECT_STYLE_CONFIG } from "./components/select";

const theme = extendTheme({
  fontSizes: {
    md: {
      fontSize: "4rem",
    },
  },
  colors: {
    brandPrimary: {
      50: "#F9FAFF",
      100: "#F1F3FF",
      200: "#E5E9FA",
      300: "#9BA5D6",
      400: "#5966A6",
      500: "#363D63",
      600: "#2D3353",
      700: "#1B1F32",
      800: "#0F1324",
      900: "#080A14",
    },
    brandSecondary: {
      50: "#FCF0E9",
      100: "#F6D6C1",
      200: "#F0BB99",
      300: "#EAA170",
      400: "#E58648",
      500: "#DF6C20",
      600: "#B2561A",
      700: "#864113",
      800: "#592B0D",
      900: "#2D1606",
    },
  },
  shadows: {
    outline: "0 0 0 3px var(--chakra-colors-brandPrimary-500)",
  },
  components: {
    Button: BUTTON_STYLE_CONFIG,
    Checkbox: CHECKBOX_STYLE_CONFIG,
    Input: INPUT_STYLE_CONFIG,
    Text: TEXT_THEME,
    Form: FORM_STYLE,
    Select: SELECT_STYLE_CONFIG,
  },
  fonts: {
    heading: "Nunito",
    body: "Nunito",
  },
  styles: {
    global: {
      ".renderer h1": {
        fontFamily: "'Nunito', sans-serif",
        color: "#363D63",
        fontSize: "3rem",
        fontWeight: "600",
        lineHeight: "normal",
      },
      ".renderer h2": {
        fontFamily: "'Nunito', sans-serif",
        color: "#363D63",
        fontSize: "2rem",
        fontWeight: "500",
        lineHeight: "normal",
      },
      ".renderer h3": {
        fontFamily: "'Nunito', sans-serif",
        color: "#363D63",
        fontSize: "1.75rem",
        fontWeight: "400",
        lineHeight: "2rem",
      },
      ".renderer h4": {
        fontFamily: "'Nunito', sans-serif",
        color: "#363D63",
        fontSize: "1.5rem",
        fontWeight: "300",
        lineHeight: "normal",
      },
      ".renderer h5": {
        fontFamily: "'Nunito', sans-serif",
        color: "#5966A6",
        fontSize: "1.25rem",
        fontWeight: "300",
        lineHeight: "normal",
      },
      ".renderer h6": {
        fontFamily: "'Nunito', sans-serif",
        color: "#363D63",
        fontSize: "1.125rem",
        fontWeight: "300",
        lineHeight: "normal",
      },
      ".renderer p, .renderer .paragraph, .renderer ul": {
        fontFamily: "'Nunito', sans-serif",
        color: "#363D63",
        fontSize: "1.125rem",
        fontWeight: "400",
        lineHeight: "1.5rem",
        mt: "0.875rem",
        ":first-child": {
          mt: 0,
        },
      },
      ".renderer ul": {
        marginLeft: "1.5rem",
      },
    },
  },
});

export default theme;
